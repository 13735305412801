<template>
  <v-row class="match-height">
    <v-col cols="12">
      <Loading
        v-if="loadGalery"
        :full-page="true"
        :color="$vuetify.theme.themes.light.primary"
      />

      <app-card-code :title="`${$t('menu.products')} / ${$t('menu.cars')}`">
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="error"
                class="mb-4 me-3"
                :loading="loading"
                @click="$router.push({ name: 'cars-list' })"
              >
                <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
                <span>{{ $t('btn.back') }}</span>
              </v-btn>
            </v-col>
            <v-col
              v-if="!$store.state.app.onlyShow"
              cols="12"
              md="2"
            >
              <v-btn
                color="primary"
                class="mb-4 me-3"
                :loading="loading"
                @click="save()"
              >
                <span>{{ btnTitle }}</span>
                <v-icon>{{ icons.mdiUpdate }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-form
            v-if="!isLoading"
            class="multi-col-validation"
          >
            <v-row class="pb-5">
              <v-col
                cols="12"
                md="4"
              >
                <v-autocomplete
                  v-model="model.marca_id"
                  :items="itemsMarcas"
                  :search-input.sync="searchMarca"
                  hide-details
                  hide-selected
                  :label="$t('menu.marca')"
                  outlined
                  dense
                  item-text="name"
                  item-value="id"
                  :disabled="$store.state.app.onlyShow"
                  @change="changeMarca"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        {{ $t('menu.marca') }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{ item }">
                    <span v-text="item.name"></span>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item-avatar v-if="item.icon !== null">
                      <v-img
                        size="20"
                        :src="rutaPublic + item.icon"
                      ></v-img>
                    </v-list-item-avatar>
                    <v-list-item-avatar v-else>
                      <v-avatar
                        color="primary"
                        size="30"
                      >
                        <span class="white--text text-h5">{{ item.name.substr(0, 1) }}</span>
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.name }}</v-list-item-title>
                      <v-list-item-subtitle></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <!--<v-autocomplete
                  v-model="model.modelo_id"
                  :items="itemsModelos"
                  :search-input.sync="searchModelo"
                  hide-details
                  hide-selected
                  :label="$t('menu.modelo')"
                  outlined
                  dense
                  item-text="name"
                  item-value="id"
                  @change="changeModelo"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        {{ $t('menu.modelo') }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{ item }">
                    <span v-text="item.name"></span>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title>{{ item.name }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>-->
                <v-select
                  v-model="model.modelo_id"
                  :items="modelos"
                  item-text="name"
                  item-value="id"
                  :label="$t('menu.modelo')"
                  outlined
                  :disabled="$store.state.app.onlyShow"
                  dense
                  hide-details
                  @change="changeModelo"
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-select
                  v-model="model.transmision_id"
                  :items="transmisiones"
                  item-text="name"
                  item-value="id"
                  :label="$t('menu.trasnmision')"
                  :disabled="$store.state.app.onlyShow"
                  outlined
                  dense
                  hide-details
                  @change="changeTransmision"
                ></v-select>
              </v-col>

              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model="model.name"
                  :label="$t('lbl.name')"
                  outlined
                  dense
                  hide-details
                  :disabled="$store.state.app.onlyShow"
                  @input="changeCumpleTags()"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-autocomplete
                  v-model="model.tipo_auto_id"
                  :items="itemsTypeAuto"
                  :search-input.sync="searchTypeAuto"
                  hide-details
                  hide-selected
                  :label="$t('menu.typeCar')"
                  :disabled="$store.state.app.onlyShow"
                  outlined
                  dense
                  item-text="name"
                  item-value="id"
                  @change="changeCumpleTags()"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        {{ $t('menu.typeCar') }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{ item }">
                    <span v-text="item.name"></span>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title>{{ item.name }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
                <!--<v-select
                  v-model="model.tipo_auto_id"
                  :items="typeAuto"
                  item-text="name"
                  item-value="id"
                  :label="$t('menu.typeCar')"
                  outlined
                  dense
                  hide-details
                ></v-select>-->
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-select
                  v-model="model.combustible_id"
                  :items="combustibles"
                  item-text="name"
                  item-value="id"
                  :label="$t('menu.combustible')"
                  :disabled="$store.state.app.onlyShow"
                  outlined
                  dense
                  hide-details
                  @change="changeCumpleTags()"
                ></v-select>
              </v-col>

              <v-col
                cols="12"
                md="3"
              >
                <v-text-field
                  v-model="model.cant_puertas"
                  :label="$t('lbl.cantPuertas')"
                  outlined
                  dense
                  hide-details
                  type="number"
                  min="0"
                  :disabled="$store.state.app.onlyShow"
                  @input="changeCumpleTags()"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <v-text-field
                  v-model="model.cant_paxs"
                  :label="$t('lbl.cantPaxs')"
                  outlined
                  dense
                  hide-details
                  type="number"
                  min="0"
                  :disabled="$store.state.app.onlyShow"
                  @input="changeCumpleTags()"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <v-text-field
                  v-model="model.cant_maletas_small"
                  :label="$t('lbl.cantMaletas')"
                  outlined
                  dense
                  hide-details
                  type="number"
                  min="0"
                  :disabled="$store.state.app.onlyShow"
                  :suffix="$t('lbl.small')"
                  @input="changeCumpleTags()"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <v-text-field
                  v-model="model.cant_maletas_big"
                  :label="$t('lbl.cantMaletas')"
                  outlined
                  dense
                  hide-details
                  type="number"
                  min="0"
                  :suffix="$t('lbl.big')"
                  :disabled="$store.state.app.onlyShow"
                  @input="changeCumpleTags()"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="3"
              >
                <v-select
                  v-model="model.um_combustible"
                  :items="umCombustible"
                  :label="$t('lbl.umComb')"
                  outlined
                  dense
                  hide-details
                  :disabled="$store.state.app.onlyShow"
                  @change="changeCumpleTags()"
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <v-text-field
                  v-model="model.capacidad_combustible"
                  :label="$t('lbl.capacityComb')"
                  outlined
                  dense
                  hide-details
                  type="number"
                  min="0"
                  :suffix="model.um_combustible"
                  :disabled="$store.state.app.onlyShow"
                  @input="changeCumpleTags()"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <v-text-field
                  v-model="model.consumo_combustible"
                  :label="$t('lbl.consumoComb')"
                  outlined
                  dense
                  hide-details
                  type="number"
                  min="0"
                  :disabled="$store.state.app.onlyShow"
                  :suffix="model.um_combustible !== undefined ? `km/${model.um_combustible}` : ''"
                  @input="changeCumpleTags()"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <v-text-field
                  v-model="model.motor"
                  :label="$t('lbl.motor')"
                  outlined
                  dense
                  hide-details
                  type="number"
                  min="0"
                  suffix="L"
                  :disabled="$store.state.app.onlyShow"
                  @input="changeCumpleTags()"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="12"
              >
                <strong>{{ $t('menu.caracteristicas') }}</strong>
                <v-row>
                  <v-col
                    v-for="(cara, i) in caracteristicas"
                    :key="i"
                    cols="12"
                    md="3"
                  >
                    <v-checkbox
                      v-model="modelCaracteristicas"
                      :label="cara.name"
                      :value="cara.id"
                      hide-details
                      :disabled="$store.state.app.onlyShow"
                      :prepend-icon="cara.icon != null ? cara.icon : null"
                      @click="changeCumpleTags()"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-col>

              <v-col
                cols="12"
                md="3"
              >
                <v-switch
                  v-model="model.publico"
                  :label="$t('lbl.publico')"
                  color="primary"
                  hide-details
                  :disabled="!cumpleTags || $store.state.app.onlyShow"
                ></v-switch>
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-switch
                      v-model="model.sugerido"
                      :label="$t('lbl.sugerido')"
                      color="primary"
                      hide-details
                      :disabled="$store.state.app.onlyShow"
                      @change="changeSugerido"
                    ></v-switch>
                  </v-col>
                  <v-col
                    v-if="model.sugerido"
                    cols="12"
                    md="4"
                  >
                    <v-select
                      v-model="model.number_sugerido"
                      :items="numeros"
                      :label="$t('lbl.orderSugerido')"
                      outlined
                      dense
                      hide-details
                      class="mt-2"
                      :disabled="$store.state.app.onlyShow"
                      @change="changeCumpleTags()"
                    ></v-select>
                  </v-col>
                  <v-col
                    v-if="model.sugerido"
                    cols="12"
                    md="2"
                    class="pt-2"
                  >
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon color="primary">
                            mdi-information-outline
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('lbl.infoSugerido') }}</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <fragment v-if="model.id">
                <v-col
                  cols="12"
                  md="12"
                >
                  <strong>{{ $t('lbl.galery') }}</strong>
                </v-col>
                <v-col
                  v-if="!$store.state.app.onlyShow"
                  cols="12"
                  md="6"
                >
                  <v-file-input
                    v-model="galery"
                    accept=".png, .jpg, .jpeg, .webp"
                    exc
                    show-size
                    outlined
                    dense
                    :label="$t('lbl.searchImages')"
                    hide-details
                    multiple
                    :disabled="$store.state.app.onlyShow || loadGalery"
                    @change="setGalery"
                  >
                    <v-tooltip
                      slot="append-outer"
                      top
                      color="error"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          v-bind="attrs"
                          class="mt-0 pb-3"
                          v-on="on"
                        >
                          <v-icon color="error">
                            {{ icons.mdiInformationOutline }}
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('lbl.cantImgUpload', { item: 20 }) }}</span>
                    </v-tooltip>
                  </v-file-input>
                </v-col>

                <fragment v-if="nameB2B !== 'ttttt' && !$store.state.app.onlyShow">
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-file-input
                      v-model="videos"
                      accept="video/*"
                      exc
                      show-size
                      outlined
                      dense
                      :label="$t('lbl.searchVideos')"
                      hide-details
                      multiple
                      :disabled="$store.state.app.onlyShow || loadGalery"
                      @change="setVideos"
                    >
                      <v-tooltip
                        slot="append-outer"
                        top
                        color="error"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            v-bind="attrs"
                            class="mt-0 pb-3"
                            v-on="on"
                          >
                            <v-icon color="error">
                              {{ icons.mdiInformationOutline }}
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('lbl.sizeVideoUpload', { item: 5 }) }}</span>
                      </v-tooltip>
                    </v-file-input>
                  </v-col>

                  <v-col
                    cols="12"
                    md="12"
                  >
                    <strong>{{ $t('lbl.videosUrl') }}</strong>
                    <v-tooltip
                      v-if="!$store.state.app.onlyShow"
                      top
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="mx-2"
                          fab
                          dark
                          x-small
                          color="primary"
                          @click="adicionarUrlVideo()"
                        >
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                          >
                            {{ icons.mdiPlus }}
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('btn.insert') }}</span>
                    </v-tooltip>
                  </v-col>
                </fragment>
                <VideosURL
                  v-for="(url, i) in videos_url"
                  :key="i"
                  :url="url"
                  :pos="i"
                />

                <Videos
                  v-for="(video, indV) in videosCars"
                  :key="Math.random() * (indV + 1)"
                  :pos="indV"
                  :video="video"
                  @reset-video-item="resetVideoItem()"
                />

                <Galery
                  v-for="(gale, i) in galeriaCar"
                  :key="i"
                  :pos="i"
                  :image="gale"
                  @change-cumple-tags="changeCumpleTags()"
                  @reset-galery-item="resetGaleryItem()"
                />
              </fragment>
              <fragment v-else>
                <v-col
                  cols="12"
                  md="2"
                >
                  <v-btn
                    v-if="!$store.state.app.onlyShow"
                    color="primary"
                    class="mb-4 me-3"
                    :loading="loading"
                    @click="addImg()"
                  >
                    <span>{{ $t('lbl.addImages') }}</span>
                    <v-icon>{{ icons.mdiImagePlus }}</v-icon>
                  </v-btn>
                </v-col>
                <v-col
                  v-if="loadGalery"
                  cols="12"
                  md="12"
                >
                  <v-skeleton-loader type="list-item-avatar"></v-skeleton-loader>
                </v-col>
              </fragment>
            </v-row>
          </v-form>
        </v-card-text>
      </app-card-code>
    </v-col>
  </v-row>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
// eslint-disable-next-line import/no-unresolved
import AppCardCode from '@/@core/components/app-card-code/AppCardCode.vue'
// eslint-disable-next-line import/no-unresolved
import Loading from '@/views/utils/loading/bars.vue'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiTrashCan,
  mdiAirplaneTakeoff,
  mdiCity,
  mdiImagePlus,
  mdiInformation,
  mdiInformationOutline,
} from '@mdi/js'
// eslint-disable-next-line import/no-unresolved
import VideosURL from '@/views/utils/videos/Videos.vue'
import Galery from '../utils/Galery.vue'
import Videos from '../utils/Videos.vue'

export default {
  components: {
    AppCardCode,
    Loading,
    Galery,
    Videos,
    VideosURL,
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      nameB2B: process.env.VUE_APP_PROJ_NAME,
      isLoading: true,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiTrashCan,
        mdiAirplaneTakeoff,
        mdiCity,
        mdiImagePlus,
        mdiInformation,
        mdiInformationOutline,
      },
      transmisiones: [],
      marcas: [],
      combustibles: [],
      caracteristicas: [],
      modelCaracteristicas: [],
      model: {},
      loading: false,

      items: [],
      itemsMarcas: [],
      searchMarca: null,
      modelos: [],
      itemsModelos: [],
      searchModelo: null,

      typeAuto: [],
      itemsTypeAuto: [],
      searchTypeAuto: null,

      numeros: [],
      umCombustible: [],
      galery: [],
      loadGalery: false,
      cumpleTags: false,
      galeriaCar: [],
      isLoadingGalery: false,
      videos: [],
      videosCars: [],
      isLoadingVideo: false,
    }
  },
  computed: {
    btnTitle() {
      return sessionStorage.getItem('cars-id') === null ? this.$t('btn.send') : this.$t('btn.update')
    },
    ...mapState({
      videos_url: state => state.app.videos_url,
    }),
  },
  watch: {
    searchMarca(val) {
      if (val !== undefined && val.length > 0) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterMarca(val.toLowerCase())
      } else {
        this.itemsMarcas = []
      }
    },
    searchModelo(val) {
      if (val.length > 0) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterModelo(val.toLowerCase())
      } else {
        this.itemsModelos = []
      }
    },
    searchTypeAuto(val) {
      if (val.length > 0) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterTypeAuto(val.toLowerCase())
      } else {
        this.itemsTypeAuto = []
      }
    },
  },
  created() {
    this.umCombustible.push('L')
    this.umCombustible.push('gal')

    // eslint-disable-next-line no-plusplus
    for (let index = 1; index <= 10; index++) {
      this.numeros.push(index)
    }

    if (sessionStorage.getItem('cars-show') !== null) {
      this.setOnlyShow(true)
    } else {
      this.setOnlyShow(false)
    }

    this.getMarcas()

    // this.getTypeAuto()
    // this.getItem()
    this.getTransmisiones()
    this.getCombustibles()
    this.getCaracteristicas()
  },
  methods: {
    ...mapMutations([
      'adicionarImgCars',
      'adicionarVideoCars',
      'updateVideosCars',
      'setOnlyShow',
      'updateUrlVideo',
      'adicionarUrlVideo',
    ]),
    filterMarca(v) {
      this.itemsMarcas = []
      if (v === '') {
        this.itemsMarcas = []
      } else {
        this.itemsMarcas = this.marcas.filter(e => e.name.toLowerCase())
      }
    },
    filterModelo(v) {
      this.itemsModelos = []
      if (v === '') {
        this.itemsModelos = []
      } else {
        this.itemsModelos = this.modelos.filter(e => e.name.toLowerCase())
      }
    },
    filterTypeAuto(v) {
      this.itemsTypeAuto = []
      if (v === '') {
        this.itemsTypeAuto = []
      } else {
        this.itemsTypeAuto = this.typeAuto.filter(e => e.name.toLowerCase())
      }
    },
    changeSugerido() {
      if (!this.model.sugerido) {
        this.model.number_sugerido = null
      }
      this.changeCumpleTags()
    },

    getItem() {
      this.$store.state.app.loadEscalas = true
      if (sessionStorage.getItem('cars-id') !== null) {
        const id = sessionStorage.getItem('cars-id')
        this.axios
          .get(`cars/${id}`, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            this.model = res.data.data.data
            if (this.model.caracteristicas_id === null) {
              this.modelCaracteristicas = []
            } else {
              this.modelCaracteristicas = this.model.caracteristicas_id
            }

            const mm = this.marcas.filter(a => a.id === this.model.marca_id)[0]
            this.itemsMarcas.push(mm)

            if (mm.modelos) {
              this.modelos.push(mm.modelos.filter(a => a.id === this.model.modelo_id)[0])
              this.itemsModelos.push(mm.modelos.filter(a => a.id === this.model.modelo_id)[0])
            }

            this.itemsTypeAuto.push(this.typeAuto.filter(a => a.id === this.model.tipo_auto_id)[0])

            if (this.model.galery !== null) {
              if (this.model.galery.length > 0) {
                this.galeriaCar = this.model.galery
              } else {
                this.galeriaCar = []
              }
            } else {
              this.galeriaCar = []
            }

            if (this.model.videos !== null) {
              if (this.model.videos.length > 0) {
                this.videosCars = this.model.videos
              } else {
                this.videosCars = []
              }
            } else {
              this.videosCars = []
            }

            if (this.model.videos_url) {
              if (this.model.videos_url.length > 0) {
                this.updateUrlVideo(this.model.videos_url)
              } else {
                this.updateUrlVideo([])
              }
            } else {
              this.updateUrlVideo([])
            }

            this.changeCumpleTags()
          })
          .finally(() => {
            this.isLoading = false
          })
      } else {
        this.updateVideosCars([])
        this.changeCumpleTags()
        this.updateUrlVideo([])
        this.isLoading = false
      }
    },

    getMarcas() {
      this.axios
        .get(`nom_marca_cars?per_page=1000&perfil_slug=${sessionStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.marcas = res.data.data
        })
        .finally(() => {
          this.getTypeAuto()
        })
    },
    getTransmisiones() {
      this.axios
        .get(`nom_transmision_cars?per_page=1000&perfil_slug=${sessionStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.transmisiones = res.data.data
        })
    },
    getTypeAuto() {
      this.axios
        .get(`nom_type_auto_cars?per_page=1000&perfil_slug=${sessionStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.typeAuto = res.data.data
        })
        .finally(() => {
          this.getItem()
        })
    },
    getCombustibles() {
      this.axios
        .get(`nom_combustible_cars?per_page=1000&perfil_slug=${sessionStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.combustibles = res.data.data
        })
    },
    getCaracteristicas() {
      this.axios
        .get(`nom_caracteristica_cars?per_page=1000&perfil_slug=${sessionStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.caracteristicas = res.data.data
        })
    },

    save() {
      if (
        this.model.name
        && this.model.marca_id
        && this.model.modelo_id
        && this.model.transmision_id
        && this.model.tipo_auto_id
        && this.model.combustible_id
      ) {
        this.loading = true
        const json = {
          name: this.model.name,
          marca_id: this.model.marca_id,
          modelo_id: this.model.modelo_id,
          transmision_id: this.model.transmision_id,
          tipo_auto_id: this.model.tipo_auto_id,
          combustible_id: this.model.combustible_id,
          cant_puertas: this.model.cant_puertas,
          cant_paxs: this.model.cant_paxs,
          cant_maletas_small: this.model.cant_maletas_small,
          cant_maletas_big: this.model.cant_maletas_big,
          um_combustible: this.model.um_combustible,
          capacidad_combustible: this.model.capacidad_combustible,
          consumo_combustible: this.model.consumo_combustible,
          motor: this.model.motor,
          caracteristicas: this.modelCaracteristicas,
          publico: this.model.publico,
          sugerido: this.model.sugerido,
          number_sugerido: this.model.number_sugerido,

          // galery: this.$store.state.app.galeria,
          // videos: this.$store.state.app.videos,
          videos_url: this.videos_url,

          perfil_slug: sessionStorage.getItem('perfil'),
        }

        // console.log(json)

        if (sessionStorage.getItem('cars-id') === null) {
          this.axios
            .post('cars', json, {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(response => {
              // console.log(response)
              if (response.data.success === false) {
                if (response.data.data.status === 401) {
                  // this.$toast.error(this.$t('msg.noAutorice'))
                } else if (response.data.data.status === 402) {
                  this.$toast.error(this.$t('msg.existProduct', { name: this.model.name }))
                }
              } else {
                sessionStorage.removeItem('cars-id')
                this.$router.push({ name: 'cars-list' })
              }
            })
            .catch(error => console.log(error))
            // eslint-disable-next-line no-return-assign
            .finally(() => (this.loading = false))
        } else {
          this.axios
            .put(`cars/${this.model.id}`, json, {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(response => {
              if (response.data.success === false) {
                // this.$toast.error(this.$t('msg.noAutorice'))
              } else {
                sessionStorage.removeItem('cars-id')
                this.$router.push({ name: 'cars-list' })
              }
            })
            .catch(error => console.log(error))
            // eslint-disable-next-line no-return-assign
            .finally(() => (this.loading = false))
        }
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },
    addImg() {
      if (
        this.model.name
        && this.model.marca_id
        && this.model.modelo_id
        && this.model.transmision_id
        && this.model.tipo_auto_id
        && this.model.combustible_id
      ) {
        this.loadGalery = true
        const json = {
          name: this.model.name,
          marca_id: this.model.marca_id,
          modelo_id: this.model.modelo_id,
          transmision_id: this.model.transmision_id,
          tipo_auto_id: this.model.tipo_auto_id,
          combustible_id: this.model.combustible_id,
          cant_puertas: this.model.cant_puertas,
          cant_paxs: this.model.cant_paxs,
          cant_maletas_small: this.model.cant_maletas_small,
          cant_maletas_big: this.model.cant_maletas_big,
          um_combustible: this.model.um_combustible,
          capacidad_combustible: this.model.capacidad_combustible,
          consumo_combustible: this.model.consumo_combustible,
          motor: this.model.motor,
          caracteristicas: this.modelCaracteristicas,
          publico: this.model.publico,
          sugerido: this.model.sugerido,
          number_sugerido: this.model.number_sugerido,

          perfil_slug: sessionStorage.getItem('perfil'),
        }

        // console.log(json)

        this.axios
          .post('cars', json, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(response => {
            // eslint-disable-next-line no-empty
            if (response.data.success === false) {
            } else {
              const { id } = response.data.data.data
              sessionStorage.setItem('cars-id', id)
              this.model.id = id
            }
          })
          .catch(error => console.log(error))
          // eslint-disable-next-line no-return-assign
          .finally(() => (this.loadGalery = false))
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },
    resetGaleryItem() {
      if (sessionStorage.getItem('cars-id') !== null) {
        this.isLoadingGalery = true
        const id = sessionStorage.getItem('cars-id')
        this.axios
          .get(`cars/${id}`, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            if (res.data.data.data.galery) {
              if (res.data.data.data.galery.length > 0) {
                this.galeriaCar = res.data.data.data.galery
              } else {
                this.galeriaCar = []
              }
            } else {
              this.galeriaCar = []
            }
          })
          // eslint-disable-next-line no-return-assign
          .finally(() => {
            this.isLoadingGalery = false
          })
      }
    },
    resetVideoItem() {
      if (sessionStorage.getItem('cars-id') !== null) {
        this.isLoadingGalery = true
        const id = sessionStorage.getItem('cars-id')
        this.axios
          .get(`cars/${id}`, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            if (res.data.data.data.videos) {
              if (res.data.data.data.videos.length > 0) {
                this.videosCars = res.data.data.data.videos
              } else {
                this.videosCars = []
              }
            } else {
              this.videosCars = []
            }
          })
          // eslint-disable-next-line no-return-assign
          .finally(() => {
            this.isLoadingGalery = false
          })
      }
    },

    changeMarca() {
      if (this.model.marca_id) {
        const marc = this.marcas.filter(a => a.id === this.model.marca_id)[0]
        this.modelos = marc.modelos

        this.model.name = `${marc.name}`
      } else {
        this.modelos = []
        this.model.name = null
      }
      this.changeCumpleTags()
    },
    changeModelo() {
      if (this.model.modelo_id) {
        const mod = this.modelos.filter(a => a.id === this.model.modelo_id)[0]

        this.model.name = `${this.model.name} ${mod.name}`
      } else {
        this.model.name = null
      }
      this.changeCumpleTags()
    },
    changeTransmision() {
      if (this.model.transmision_id) {
        const trans = this.transmisiones.filter(a => a.id === this.model.transmision_id)[0]

        this.model.name = `${this.model.name} (${trans.name})`
      } else {
        this.model.name = null
      }
      this.changeCumpleTags()
    },

    setGalery() {
      if (this.galery.length > 0) {
        if (this.galery.length <= 20) {
          this.loadGalery = true
          const formData = new FormData()
          this.galery.forEach(element => {
            formData.append('images[]', element)
          })
          this.axios
            .post('galery/car', formData, {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(response => {
              if (response.data.data.imgsNotSize) {
                if (response.data.data.imgsNotSize.length > 0) {
                  response.data.data.imgsNotSize.forEach(element => {
                    this.$toast.error(element)
                  })
                  this.$toast.error(this.$t('msg.msgImgsNotSize', { dimensions: '1080x720' }))
                }
              }

              if (response.data.data.data.length > 0) {
                const imgs = response.data.data.data
                const { resource } = response.data.data
                imgs.forEach(element => {
                  this.galeriaCar.push({
                    tag: null,
                    principal: false,
                    resource,
                    image: element,
                  })
                })
              }

              // this.adicionarImgCars({ galery: imgs, resource })
            })
            .catch(error => console.log(error))
            // eslint-disable-next-line no-return-assign
            .finally(() => this.updateGalery())
        } else {
          this.$toast.error(this.$t('lbl.execImgUpload', { item: 20 }))
          this.galery = []
          this.loadGalery = false
        }
      }
    },
    updateGalery() {
      if (this.model.id && this.galeriaCar.length > 0) {
        const json = {
          product_id: this.model.id,
          product: 'car',
          galery: this.galeriaCar,
        }

        this.axios
          .post('galery/galery-update', json, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
          // eslint-disable-next-line no-unused-vars
          .then(response => {})
          .catch(error => console.log(error))
          // eslint-disable-next-line no-return-assign
          .finally(() => {
            this.galery = []
            this.loadGalery = false
            this.changeCumpleTags()
          })
      } else {
        this.galery = []
        this.loadGalery = false
      }
    },

    setVideos() {
      if (this.videos.length > 0) {
        if (this.videos.length <= 5) {
          this.loadGalery = true
          const formData = new FormData()
          this.videos.forEach(element => {
            formData.append('videos[]', element)
          })
          const jsonCar = { product: 'cars', product_id: sessionStorage.getItem('cars-id') }
          formData.append('data', JSON.stringify(jsonCar))
          this.axios
            .post('videos', formData, {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(response => {
              const videos = response.data.data.data
              const { resource } = response.data.data
              videos.forEach(element => {
                this.videosCars.push({
                  procesado: false,
                  resource,
                  url: element,
                })
              })
            })
            .catch(error => console.log(error))
            // eslint-disable-next-line no-return-assign
            .finally(() => this.updateVideos())
        } else {
          this.$toast.error(this.$t('lbl.execImgUpload', { item: 5 }))
          this.videos = []
          this.loadGalery = false
        }
      }
    },
    updateVideos() {
      if (this.model.id) {
        const json = {
          product_id: this.model.id,
          product: 'car',
          videos: this.videosCars,
        }

        this.axios
          .post('videos/update', json, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
          // eslint-disable-next-line no-unused-vars
          .then(response => {})
          .catch(error => console.log(error))
          // eslint-disable-next-line no-return-assign
          .finally(() => {
            this.videos = []
            this.loadGalery = false
          })
      }
    },

    changeCumpleTags() {
      if (
        !this.model.marca_id
        || !this.model.modelo_id
        || !this.model.transmision_id
        || !this.model.tipo_auto_id
        || !this.model.combustible_id
        || this.model.cant_puertas === undefined
        || this.model.cant_puertas === ''
        || this.model.cant_paxs === undefined
        || this.model.cant_paxs === 0
        || this.model.cant_paxs === ''
        || this.model.cant_maletas_big === undefined
        || this.model.cant_maletas_big === ''
        || this.model.cant_maletas_small === undefined
        || this.model.cant_maletas_small === ''
        || !this.model.um_combustible
        || this.model.capacidad_combustible === undefined
        || this.model.capacidad_combustible === ''
        || this.model.consumo_combustible === undefined
        || this.model.consumo_combustible === ''
        || !this.model.motor
        || this.modelCaracteristicas.length === 0
        || (this.model.sugerido && !this.model.number_sugerido)
        || this.galeriaCar.length === 0
      ) {
        this.cumpleTags = false
        this.model.publico = false
      } else {
        this.cumpleTags = true
      }

      let tieneP = false
      this.galeriaCar.forEach(element => {
        if (!element.tag) {
          this.cumpleTags = false
          this.model.publico = false
        }

        if (element.principal) {
          tieneP = true
        }
      })

      if (!tieneP) {
        this.cumpleTags = false
        this.model.publico = false
      }
    },
  },
}
</script>
