var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{attrs:{"cols":"12","md":"4"}},[(_vm.loading)?_c('Loading',{attrs:{"full-page":true,"color":_vm.$vuetify.theme.themes.light.primary}}):_vm._e(),_c('v-dialog',{attrs:{"scrollable":"","max-width":"350px"},model:{value:(_vm.isDialogVisibleDelete),callback:function ($$v) {_vm.isDialogVisibleDelete=$$v},expression:"isDialogVisibleDelete"}},[_c('v-card',{attrs:{"max-height":"400px"}},[_c('v-card-title',[_vm._v(_vm._s(("" + (_vm.$t('btn.delete')))))]),_c('v-divider'),_c('v-card-text',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(_vm.$t('msg.deleteImg'))+" ")]),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":function($event){_vm.isDialogVisibleDelete = !_vm.isDialogVisibleDelete}}},[_vm._v(" "+_vm._s(_vm.$t('btn.close'))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","outlined":""},on:{"click":function($event){return _vm.confirmDelete()}}},[_vm._v(" "+_vm._s(_vm.$t('btn.send'))+" ")])],1)],1)],1),_c('v-card',[_c('v-card-text',[_c('p',{staticClass:"text--primary text-base"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-img',{attrs:{"src":_vm.image.resource === 's3'
                  ? _vm.nameB2B === 'ttttt'
                    ? _vm.rutaS3 + _vm.remplaceUrl(_vm.image.image)
                    : _vm.rutaS3 + _vm.image.image
                  : _vm.rutaPublic + _vm.image.image,"size":"100","height":"125"}})],1)],1),_c('v-row',{staticClass:"pt-5",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-switch',{staticClass:"mb-2",attrs:{"label":_vm.$t('lbl.principal'),"color":"primary","hide-details":"","disabled":_vm.$store.state.app.onlyShow || _vm.editando},on:{"click":function($event){_vm.$emit('change-cumple-tags')
                _vm.updateImg()}},model:{value:(_vm.image.principal),callback:function ($$v) {_vm.$set(_vm.image, "principal", $$v)},expression:"image.principal"}})],1),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-select',{attrs:{"items":_vm.tags,"label":"Tag","outlined":"","dense":"","item-text":"name","item-value":"id","hide-details":"","disabled":_vm.$store.state.app.onlyShow || _vm.editando},on:{"change":function($event){_vm.$emit('change-cumple-tags')
                _vm.updateImg()}},model:{value:(_vm.image.tag),callback:function ($$v) {_vm.$set(_vm.image, "tag", $$v)},expression:"image.tag"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"1","md":"1"}},[(!_vm.$store.state.app.onlyShow)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"fab":"","dark":"","x-small":"","color":"primary"},on:{"click":function($event){return _vm.crops()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiCrop)+" ")])],1)]}}],null,false,4218633093)},[_c('span',[_vm._v(_vm._s(_vm.$t('btn.resize')))])]):_vm._e()],1),(!_vm.$store.state.app.onlyShow)?_c('v-col',{attrs:{"cols":"12","sm":"1","md":"1"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"ml-4",attrs:{"fab":"","dark":"","x-small":"","color":"error"},on:{"click":function($event){return _vm.deleteImg(_vm.pos)}}},[_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiTrashCan)+" ")])],1)]}}],null,false,1029807621)},[_c('span',[_vm._v(_vm._s(_vm.$t('btn.delete')))])])],1):_vm._e()],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }