<template>
  <v-col
    cols="12"
    md="6"
  >
    <Loading
      v-if="loading"
      :full-page="true"
      :color="$vuetify.theme.themes.light.primary"
    />
    <v-dialog
      v-model="isDialogVisibleDelete"
      scrollable
      max-width="350px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.delete')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-2">
          {{ $t('msg.deleteVideo') }}
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isDialogVisibleDelete = !isDialogVisibleDelete"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            @click="confirmDelete()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card>
      <v-card-text>
        <p class="text--primary text-base">
          <v-row no-gutters>
            <v-col
              cols="12"
              md="12"
            >
              <Media
                :kind="'video'"
                :controls="true"
                :muted="false"
                :autoplay="false"
                :src="[
                  video.resource === 's3'
                    ? nameB2B === 'ttttt'
                      ? rutaS3 + `${video.url.replace('uploads/car/', '')}`
                      : rutaS3 + video.url
                    : rutaPublic + 'storage/' + video.url,
                ]"
                :style="{ width: '500px' }"
              >
              </Media>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              v-if="!$store.state.app.onlyShow"
              cols="12"
              sm="1"
              md="1"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="ml-4"
                    fab
                    dark
                    x-small
                    color="error"
                    @click="deleteVideo()"
                  >
                    <v-icon
                      v-bind="attrs"
                      small
                      v-on="on"
                    >
                      {{ icons.mdiTrashCan }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.delete') }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </p>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
import { mapMutations } from 'vuex'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiTrashCan,
  mdiAirplaneTakeoff,
  mdiCity,
  mdiEyePlus,
  mdiCrop,
} from '@mdi/js'
// eslint-disable-next-line import/no-unresolved
import Loading from '@/views/utils/loading/bars.vue'
import Media from '@dongido/vue-viaudio'

export default {
  components: {
    Media,
    Loading,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    video: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    pos: {
      type: Number,
    },
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      nameB2B: process.env.VUE_APP_PROJ_NAME,
      rutaS3: process.env.VUE_APP_API_URL_S3_CAR,
      isLoading: true,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiTrashCan,
        mdiAirplaneTakeoff,
        mdiCity,
        mdiEyePlus,
        mdiCrop,
      },
      model: {},
      tags: [],
      loading: false,
      isDialogVisibleDelete: false,
      editando: false,
    }
  },
  created() {},
  methods: {
    ...mapMutations(['deleteVideoCars']),
    deleteVideo() {
      this.isDialogVisibleDelete = true
    },
    confirmDelete() {
      this.loading = true
      this.isDialogVisibleDelete = false
      this.video.product = 'car'
      this.video.product_id = parseInt(sessionStorage.getItem('cars-id'), 10)
      this.axios
        .post('videos/video-delete', this.video, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        // eslint-disable-next-line no-unused-vars
        .then(response => {})
        // eslint-disable-next-line no-return-assign
        .finally(() => {
          this.loading = false
          this.$emit('reset-video-item')
        })
    },
  },
}
</script>
